var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audit-list" },
    [
      _c("div", { staticClass: "audit-list-top" }, [
        _vm._v(
          "\n        您有" +
            _vm._s(_vm.tableData.length) +
            "个组织架构创建申请审批未完成\n        "
        ),
        _c("span", [_vm._v("请及时审批一下申请")]),
      ]),
      _c(
        "div",
        { staticClass: "audit-list-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.filterTableData,
                border: "",
                height: "668",
                "tooltip-effect": "light",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  align: "center",
                  "show-overflow-tooltip": true,
                  "min-width": "200",
                  label: "部门名称",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "jobCount",
                  align: "center",
                  "min-width": "200",
                  label: "客户名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("select-popover", {
                          attrs: {
                            enableSearch: true,
                            enableCount: true,
                            selectItem: _vm.customerNameSearchGroupedItems,
                            label: "customerNames",
                            title: "客户名称",
                            maxSelect: 5,
                          },
                          on: { selectChange: _vm.selectDataChange },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              content: scope.row.customerName,
                              placement: "top",
                              "open-delay": 300,
                              disabled: _vm.tooltipDisabled,
                            },
                            nativeOn: {
                              mouseenter: function ($event) {
                                return _vm.handleShowTooltip($event)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(scope.row.customerName))])]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "creator",
                  align: "center",
                  "show-overflow-tooltip": true,
                  "min-width": "200",
                  label: "申请人",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "200",
                  label: "申请时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.created
                                ? scope.row.created.slice(0, 10)
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                {
                  attrs: { label: "操作", width: "200", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "approve-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleApprove(
                                    scope.$index,
                                    scope.row
                                  )
                                },
                              },
                            },
                            [_vm._v("审批")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "table-slot" }, [
                      _vm._v(
                        "\n                        操作（请处理）\n                    "
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _c("template", { slot: "empty" }, [
                _c("div", [
                  _c("span", { staticClass: "empty-img" }),
                  _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("edit-department-dialog", {
        ref: "departmentDialog",
        on: {
          "audit-resolved": _vm.handleAuditResolved,
          "audit-reject": _vm.handleAuditReject,
        },
      }),
      _c("audit-reject-dialog", {
        ref: "auditRejectDialog",
        on: { "audit-reject-done": _vm.handleAuditResolved },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }