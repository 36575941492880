<template>
    <div class="audit-list">
        <div class="audit-list-top">
            您有{{tableData.length}}个组织架构创建申请审批未完成
            <span>请及时审批一下申请</span>
        </div>
        <div class="audit-list-table">
            <el-table
                v-loading="loading"
                :data="filterTableData"
                border
                style="width: 100%"
                height="668"
                tooltip-effect="light">
                <el-table-column
                    prop="name"
                    align="center"
                    :show-overflow-tooltip="true"
                    min-width="200"
                    label="部门名称"
                ></el-table-column>
                <el-table-column
                    prop="jobCount"
                    align="center"
                    min-width="200"
                    label="客户名称"
                >
                    <template slot="header" slot-scope="scope">
                        <select-popover
                            :enableSearch="true"
                            :enableCount="true"
                            :selectItem="customerNameSearchGroupedItems"
                            :label="'customerNames'"
                            :title="'客户名称'"
                            :maxSelect="5"
                            @selectChange="selectDataChange"
                        ></select-popover>
                    </template>
                    <template slot-scope="scope">
                        <el-tooltip
                            effect="light"
                            :content="scope.row.customerName"
                            placement="top"
                            :open-delay="300"
                            :disabled="tooltipDisabled"
                            @mouseenter.native ="handleShowTooltip($event)"
                        >
                            <!-- <span
                                class="customer-detail-link"
                                @click.prevent="handleJumpDetail(scope.row, 'customer')"
                            >
                                {{scope.row.customerName}}
                            </span> -->
                            <span>{{scope.row.customerName}}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="creator"
                    align="center"
                    :show-overflow-tooltip="true"
                    min-width="200"
                    label="申请人"
                ></el-table-column>
                <el-table-column
                    align="center"
                    min-width="200"
                    label="申请时间"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.created ? scope.row.created.slice(0, 10) : ''}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="200"
                    align="center"
                >
                    <template slot="header">
                        <div class="table-slot">
                            操作（请处理）
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <span class="approve-btn" @click="handleApprove(scope.$index, scope.row)">审批</span>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <div>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div>
                </template>
            </el-table>
        </div>
        <edit-department-dialog 
            ref="departmentDialog"
            @audit-resolved="handleAuditResolved"
            @audit-reject="handleAuditReject"
        ></edit-department-dialog>
        <audit-reject-dialog ref="auditRejectDialog" @audit-reject-done="handleAuditResolved"></audit-reject-dialog>
    </div>
</template>

<script>
import DepartmentService from '#/js/service/departmentService.js';
import SelectPopover from '#/component/common/select-popover.vue';
import EditDepartmentDialog from '#/views/customers/component/edit-department-dialog.vue';
import AuditRejectDialog from './components/audit-reject-dialog.vue';
export default {
    name: "DepartmentAuditList",
    components: {
        SelectPopover,
        EditDepartmentDialog,
        AuditRejectDialog
    },
    data() {
        return {
            loading: false,
            name: "",
            tableData: [],
            filterTableData: [],
            customerNameSearchGroupedItems: [],
            customerFilter: [],
            tooltipDisabled: true,
        }
    },
    computed: {
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        isBusinessPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Business');
        },
        privilegeCodeList() {
            return this.$store.state.user.userInfo.privilegeCodeList || [];
        },
        isDepartmentPermission() {
            return this.privilegeCodeList.includes('DepartmentManageAudit') || this.privilegeCodeList.includes('DepartmentReviewer');
        },
    },
    mounted() {
        if(!this.isAdministrator && !this.isDepartmentPermission){
            shortTips("权限不足，请联系管理员")
            sessionStorage.setItem('tabItems', JSON.stringify([]));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
        
        this.getList();
    },
    methods: {
        getList() {
            this.loading = true;
            DepartmentService.getAuditList().then(res => {
                this.loading = false;
                this.tableData = res || [];
                this.getCustomerNameFilter();
                this.getFilterList();
            }).catch(err => {
                this.loading = false;
            })
        },
        getCustomerNameFilter() {
            let customerNameFilter = {}, list = [];
            this.tableData.forEach(item => {
                if(customerNameFilter[item.customerName]) {
                    customerNameFilter[item.customerName].count++;
                } else {
                    customerNameFilter[item.customerName] = {
                        label: item.customerName,
                        isSelect: this.customerFilter.indexOf(item.customerName) > -1 ? true : false,
                        count: 1
                    };
                }
            });
            Object.keys(customerNameFilter).forEach(item => {
                list.push(customerNameFilter[item]);
            })
            list.sort((item1, item2) =>{
                return (item2.count - item1.count);
            });
            this.customerNameSearchGroupedItems = list;
        },
        selectDataChange(label, result) {
            let list = [];
            result.forEach(item => {
                list.push(item.label);
            });
            this.customerFilter = list;
            this.getFilterList();
        },
        getFilterList() {
            if(this.customerFilter.length > 0) {
                let list = [];
                this.tableData.forEach(item => {
                    if(this.customerFilter.indexOf(item.customerName) > -1) {
                        list.push(item);
                    }
                })
                this.filterTableData = list;
            } else {
                this.filterTableData = this.tableData;
            }
        },
        handleShowTooltip(event){
            let cell = event.target;
            setTimeout(() => {
                this.tooltipDisabled = cell.clientWidth == cell.scrollWidth;
            }, 300);
        },
        handleApprove(index, item) {
            console.log(item);
            this.$refs.departmentDialog.show(false, {
                index: index,
                id: item.id,
                customerId: item.customerName ? item.customerId : ''
            }, true);
        },
        handleAuditResolved(index) {
            if(index !== '') {
                this.tableData.splice(index, 1);
            }
        },
        handleAuditReject(index, id, customerId, groupVersion) {
            this.$refs.auditRejectDialog.show({index, id, customerId, groupVersion});
        }
    }
}
</script>

<style lang="scss" scope>
    .audit-list {
        height: 100%;
        min-height: 500px;
        padding: 10px 20px 100px 20px;
        background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
        display: flex;
        flex-direction: column;

        &-top {
            padding-left: 11px;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            color: #333;
            background-color: #fff;

            span {
                margin-left: 20px;
                font-size: 14px;
                color: #FC7859;
            }
        }

        .audit-list-table {
            height: 60.6% !important;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            letter-spacing: 0px;
            // height: 100%;
            .el-table {
                background-color: transparent;
                .el-table__body-wrapper {
                    background-color: #fff;
                }
              
                th {
                    padding: 0;
                    height: 42px;
                    line-height: 42px;
                    background-color: #E8E8E8;
                    border-color: #CCC;
                    color: #666;
                }
                .job-category-operate {
                    .text-operate-btn + .text-operate-btn {
                        margin-left: 20px;
                    }
                }
                .approve-btn {
                    color: $primary;
                    cursor: pointer;
                }
                .table-slot {
                    padding: 0;
                    text-indent: 10px;
                    line-height: unset;
                }
                .is-center .table-slot {
                    text-indent: 0;
                }
                .el-table__empty-block {
                    margin: 0 auto;
                    
                    .el-table__empty-text {
                        line-height: inherit;
                    }
                    .empty-img {
                        display: inline-block;
                        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                        width: 240px;
                        height: 228px;
                    }
                    .empty-data {
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
</style>